import { render, staticRenderFns } from "./Plan.vue?vue&type=template&id=4b9a678c&"
import script from "./Plan.vue?vue&type=script&lang=ts&"
export * from "./Plan.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QPage} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QPage})


import { BillingModel } from '@/models/Plan';
import { handleErrorUI } from '@/util/error';
import PlanSelection from '@/components/Management/Plan/PlanSelection.vue';

export default {
  components: { PlanSelection },
  data() {
    return {
      loading: false,
      planCache: {
        [BillingModel.MONTHLY]: [],
        [BillingModel.ANNUALLY]: []
      },
      billingModel: BillingModel.MONTHLY
    };
  },
  computed: {
    currentlyShownPlans() {
      const list = this.planCache[this.billingModel];
      if (list.length === 0) {
        this.fetchPlans(this.billingModel);
      }
      return list;
    }
  },
  methods: {
    handleChangeBillingModel(newBillingModel: BillingModel) {
      this.billingModel = newBillingModel;
    },
    async fetchPlans(billingModel) {
      this.loading = true;
      try {
        const { plans } = await this.$repo.plan.getPlans(billingModel);
        this.planCache[billingModel] = plans;
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      }
    },
    redirectToPlanManagement() {
      this.$router.push({
        name: 'management:plan'
      });
    }
  }
};
